@media screen and (min-width:  320px) {
    .container{
        grid-template-columns: auto;
    }
}

@media screen and (min-width:  720px){
    .container{
        grid-template-columns: 1fr auto;
    }
}

.container{
    display: grid;
    min-height: 100vh;
    height: auto;
    background-color: #000;
    color: #FBD034;
}