@media screen and (min-width: 320px){
    .titulo{
        margin-top: 5rem;
    }

    .contentInputs{
        grid-template-columns: auto;
    }
}

@media screen and (min-width: 720px){
     .content{
        width: 50%;
     }

    .titulo{
        margin-top: 2rem;
    }

    .contentInputs{
        grid-template-columns: auto auto auto;
    }
}

.ContentC{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.titulo{
    text-align: center;
}

.content{
    padding: 1rem; 
    margin-top: 1rem;

    background-color: #999999;
    border-radius: 0.6rem;
    border: 0.2rem solid #FBD034;
}

.contentInputs{
    display: grid;
}

.divBtn{
    text-align: center;
}