.input{
    font-size: 0.9rem;
    margin-bottom: 1.6rem;
    padding: 0.5rem;
    
    outline: none;
    border: 0.1rem solid #000;
}
input::placeholder{
    font-size: 0.75rem;
    color: #000;
}

.label{
    font-size: 1.2rem;
}

.esquerda{
    text-align: left;
}

.unidadeTurma{
    max-width: 50%;
    min-width: 50%;
}
.professorTurma{
    max-width: 50%;
    min-width: 50%;
}

@media screen and (min-width:  320px) {
    .input{
        margin-right: 0;
    }

    .contentImagem{
        width: 100%;
    }
}

@media screen and (min-width:  720px) {
    .input{
        margin-right: 0.8rem;
    }

    .contentImagem{
        width: 90%;
    }
}



.contentImagem{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.inputImagem{
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.2rem;
    background-color: #fff;
    cursor: pointer;
}
.inputImagem:hover{
    background-color: #e2e2e2;
    transition: 0.5s;
}

.divDaImagem{
    text-align: center;
}
.imagemEnviada{
    margin-top: 0.2rem;
    width: 7rem;
    height: 7rem;
    border-radius: 100%;
}