@media screen and (min-width: 320px){
    .titulo{
        margin-top: 5rem;
        margin-bottom: 1rem;
    }

    .divBtn{
        width: 95%;
    }

    .qtd{
        margin-left: 0.2rem;
        margin-right: 0.8rem;
        font-size: 0.8rem;
    }

    .tabela{
        width: 95%;
    }

    .Id{
        margin-right: 0;
    }

    .colunaNome{
        min-width: 10rem;
    }
}

@media screen and (min-width: 720px){
    .titulo{
        margin-right: 2rem;
        margin-top: 0.5rem;
    }

    .divBtn{
        width: 55%;
    }

    .qtd{
        font-size: 1rem;
    }

    .tabela{
        width: 55%;
    }

    .Id{
        margin-right: 1rem;
    }

    .colunaNome{
        min-width: 25rem;
    }
}

table{
    min-height: 70vh;
    height: auto;
}

td{
    line-height: 1rem;
    border: 0.1rem solid #fff;
    padding: 0.3rem;
}

.contentAdm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
}

.divBtn{
    display: flex;
    justify-content: space-between;
}

.tabela{
    background-color: #838383;
    border-collapse: collapse;
}

.divIds{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row-reverse;
}

.ids{
    text-align: center;
}

.icon{
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.1rem;
    border-radius: 0.3rem;

    cursor: pointer;
}
.icon:hover{
    background-color: #FBD034;
    transition: 0.5s;
}