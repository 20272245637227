.btn{
    background-color: #000;
    color: #fff;
    border-radius: 3rem;
    border: 0;
    padding: 0.4rem 3rem 0.4rem 3rem;
    cursor: pointer;
    margin-bottom: 1rem;
}
.btn:hover{
    transition: 0.5s;
    background-color: #f5f5f5;
    color: #000;
}