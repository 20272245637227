/* Background Responsivo */
.backgroundContainer {
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
}
.responsiveImg {
    width: 100%;
    height: 100%;
}


.container_formlogin{
    width: 100vw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form{
    position: relative;
    text-align: center;
    font-size: 1rem;

    background-color: #fff;
    box-sizing: border-box;
    z-index: 1;

    /* border: solid 0.3rem;
    border-image: linear-gradient(90deg, #EFBF14, #000) 1;  */

    border: solid 0.4rem transparent;
    border-radius: 2rem;
    background:
    linear-gradient( #fff, #fff) padding-box,
    linear-gradient(90deg, #EFBF14, #000) border-box;
}

.form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../imgs/logo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3;
    z-index: -1;
}

@media screen and (min-width:  320px) {
    .form {
        padding: 2.4rem;
    }
}

@media screen and (min-width:  400px) {
    .form {
        padding: 3.4rem;
    }
}