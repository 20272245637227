.container_formcadastro{
    background-color: #000;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form{
    display: grid;
    position: relative;
    text-align: center;
    font-size: 1rem;

    background-color: #e0e0e0;
    box-sizing: border-box;
    z-index: 1;
}
.form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../imgs/logo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3;
    z-index: -1;
}

.container_inputs{
    display: grid;
}

@media screen and (min-width:  320px) {
    .container_formcadastro{
        height: 100%;
    }
    .form {
        padding: 2.4rem;
        width: 100%;
        border-radius: 0;
    }
    .container_inputs{
        grid-template-columns: auto;
        margin: auto;
    }
}

@media screen and (min-width: 720px) {
    .container_formcadastro{
        height: 100vh;
    }
    .form{
        padding: 0.8rem;
        width: auto;
        border: #000 solid;
        border-radius: 1rem;
        box-shadow: 0 0 2.5rem #ffffff;
    }
    .container_inputs{
        grid-template-columns: auto auto;
    }
}


.textcenter{
    text-align: center;
    padding: 0.3rem;
    background-color: #000;
    border: 0;
    color: #fff;
    border-radius: 0.4rem;
    margin-bottom: 2rem;
    font-size: 0.9rem;
}


.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.button {
    margin-top: 1rem;
    width: 10rem;
    height: 3rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}
.button:hover {
    transition: 0.5s;
    background-color: #363636;
}