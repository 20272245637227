@media screen and (min-width: 320px){
    .container_alunoscalendario{
        width: 100%;
        margin-top: 5rem;

        border: 0;
        border-radius: 0;
        background-color: #2b2b2b;
    }
}

@media screen and (min-width: 720px){
    .container_alunoscalendario{
        width: 50%;
        min-height: 70vh;
        height: auto;
        margin-top: 5rem;

        border: 0.1rem #FBD034 solid;
        border-radius: 0.5rem;
        background-color: #2b2b2b;
    }
}

ul{
    list-style: none;
}

.ajuste{
    display: flex;
    justify-content: center;
}

.textTurma{
    margin: 0.4rem;
    font-size: 1.6rem;
}
.textLH{
    margin:  0.4rem;
    font-size: 1rem;
}

.lista{
    margin: 1rem;
    line-height: 1.5rem;
    font-size: 0.8rem;
    color: #ccc;
}