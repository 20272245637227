/* Background Responsivo */
.backgroundContainer {
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
}
.responsiveImg {
    width: 100%;
    height: 100%;
}

/* Estilo Principal */
.container{
    width: 100vw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form{
    width: 50rem;
    height: 30rem;
    background-color: #fff;
    border: solid 0.4rem transparent;
    border-radius: 2rem;
    background:
        linear-gradient( #fff, #fff) padding-box,
        linear-gradient(90deg, #EFBF14, #000) border-box;
}

.container_inputs{
    display: grid;
    min-width: 28.9rem;
}

.textcenter{
    width: 30rem;
    padding: 0.3rem;
    background-color: #000;
    border: 0;
    color: #fff;
    border-radius: 0.4rem;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 0.9rem;
}

.centro{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.iconNavegar{
    margin: 0 0.2rem 0 0.2rem;
    width: 1rem;
    height: 1rem;
}

@media screen and (min-width:  720px) {
    .container_inputs{
        grid-template-columns: repeat(2, 1fr);
        gap: 0 1rem;
    }
}


/* Estilo do Input de Radio */
input[type="radio"] {
    display: none;
}
input[type="radio"]:checked + label {
    background-color: #EFBF14;
}

.divRadio{
    margin: 0 1.2rem 1.6rem 1.2rem;
}

.labelTextCenter{
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.opcoes{
  display: flex;
}

.escolha{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 3.5rem;
  background-color: #fff;
  border-radius: 0.8rem;
  cursor: pointer;
  font-size: 1rem;
}


/* Estilo Botão */
.button{
    width: 95%;
    background-color: #EFBF14;
    color: #000;
    border: none;
    border-radius: 2rem;
    padding: 0.5rem 0 0.5rem 0;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}
.button:hover{
    background-color: #efaa14;
}

.divBotoes{
    display: flex;
    width: 100%;
}
.divBotoes>button{
    margin: 1rem;
}

/* Estilo Passo 4 */
.checkboxContainer{
    margin-bottom: 2rem;
    padding: 0 5rem;
    user-select: none;
}

/* Estilo do Passo 8 */
.container_Passo8{
    display: flex;
    flex-direction: column;
    user-select: none;
}

/* Estilo do Passo Escolhas */
.container_Passo_Escolhas {
    user-select: none;
}

/* Estilo Escolha Unidade e Turma */
.divSelect {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.select {
    appearance: none; /* Remove a seta padrão do select */
    cursor: pointer;

    text-align: center;
    font-size: 1rem;
    text-overflow: ellipsis; /* Coloca '...' se o texto for grande */
    
    background:
        linear-gradient(#fff, #fff) padding-box,
        linear-gradient(90deg, #EFBF14, #000) border-box;
    border-radius: 2rem;
    border: 0.2rem solid transparent;

    padding: 1rem;
    min-width: 30rem;
    max-width: 30rem;
    
    white-space: nowrap; /* Impede quebra de linha */
    overflow: hidden; /* Garante que o texto não ultrapasse o limite */
}

.select:focus {
    outline: none;
    border-color: transparent;
}

option{
    background-color: #EFBF14;
    color: #000;
}

/* Estilo do Contrato */
.divContrato {
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem; /* Reduzi o espaçamento entre os elementos */
}
  
.contrato {
    max-height: 15rem; /* Define a altura máxima para o contrato */
    overflow-y: scroll; /* Ativa o scroll vertical */
    padding: 1rem;
    border: 0.1rem solid #000; /* Adiciona uma borda para delimitar o contrato */
    background-color: #fff;
    line-height: 1.5;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem; /* Espaço entre o checkbox e o texto */
    margin-top: 2rem;
}