.input{
    font-size: 1rem;
    margin-bottom: 2rem;
    background: none;
    padding: 0.6rem 0.6rem 0.6rem 0;

    outline: none;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-color: #000;
}
input::placeholder{
    color: #000;
}

.label{
    font-size: 1.3rem;
}

.esquerda{
    text-align: left;
}