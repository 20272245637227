@media screen and (min-width: 320px){
    .display_grid{
        grid-template-columns: auto;
        margin-top: 5rem;
    }
}

@media screen and (min-width: 720px){
    .display_grid{
        grid-template-columns: repeat(2, 1fr);
        margin-top: 1rem;
    }
}

.container_adm{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-height: 100vh;
    height: auto;
}
.display_grid{
    display: grid;
}