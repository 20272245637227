img{
    width: 10rem;
    height: 10rem;
}

.cards_adm{
    background-color: #999999;
    font-size: 0.5rem;
    text-align: center;
    margin: 2rem;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: #FBD034 solid 0.1rem;
    cursor: pointer;
}